import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import { TopPage } from '~/features'
import { TopFooter, TopHeader } from '~/features/common'
import { getAllPrefectures } from '~/models/master.server'
import { searchOffice } from '~/models/office.server'
import { Office, Prefecture } from '~/models/types'

const getFeaturedOffices = async (): Promise<Office[]> => {
  const { offices } = await searchOffice({
    take: 10,
    sortOrder: 'RECENTLY_UPDATED',
  })
  console.log('Featured offices query conditions:', {
    take: 10,
    sortOrder: 'RECENTLY_UPDATED',
  })
  console.log('Featured offices result:', {
    count: offices.length,
    officeIds: offices.map((o) => o.id),
    officeNames: offices.map((o) => o.name),
    spacesCounts: offices.map((o) => o.spaces.length),
  })
  return offices
}

const getNewOffices = async (): Promise<Office[]> => {
  const { offices: recentOffices } = await searchOffice({
    sortOrder: 'NEWEST',
  })
  console.log('TOPページ - 新着オフィス取得結果:', {
    query: { sortOrder: 'NEWEST' },
    offices: recentOffices.map((o) => ({
      id: o.id,
      name: o.name,
      createdAt: o.createdAt,
      updatedAt: o.updatedAt,
      prefecture: o.areas?.[0]?.prefectures?.[0]?.name,
    })),
  })
  return recentOffices
}

const getPageData = async (): Promise<{
  prefectures: Prefecture[]
  featuredOffices: Office[]
  newOffices: Office[]
} | null> => {
  const prefectures = await getAllPrefectures()
  if (!prefectures) return null

  const [featuredOffices, newOffices] = await Promise.all([
    getFeaturedOffices(),
    getNewOffices(),
  ])

  return { prefectures, featuredOffices, newOffices }
}

export const loader = async () => {
  const data = await getPageData()
  console.log('data:', data)

  if (!data)
    throw new Response('Not Found', { status: 404, statusText: 'Not Found.' })

  return json(data)
}

export default function Index() {
  const { prefectures, newOffices: jsonifiedNewOffices } =
    useLoaderData<typeof loader>()

  // 新着物件の日付データも変換
  const newOffices: Office[] = jsonifiedNewOffices.map((office) => ({
    ...office,
    spaces: office.spaces.map((space) => ({
      ...space,
      createdAt: new Date(space.createdAt),
    })),
  }))

  return (
    <div className="flex min-h-screen flex-col">
      <TopHeader />
      <div className="flex-grow pt-[58px]">
        <TopPage newOffices={newOffices} prefectures={prefectures} />
      </div>
      <TopFooter />
    </div>
  )
}
