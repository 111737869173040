import { useState, useRef, useEffect } from 'react'

import { cn } from '~/lib/utils'
// Custom data for the featured offices with specific images and taglines
const FEATURED_OFFICES = [
  {
    id: 1,
    name: 'VLAG YOKOHAMA',
    tagline:
      '横浜駅直結ビルの最上階。\n多様な交流の場である複合型オフィスで事業共創を目指す。',
    imageUrl: '/images/featured-offices/Vlagyokohama.webp',
    link: 'https://media.officemap.jp/vlag-yokohama/',
  },
  {
    id: 2,
    name: 'CROSS COOP 新橋',
    tagline:
      '「新橋」駅から4分の好立地\n共用部の設備も充実したレンタルオフィス',
    imageUrl: '/images/featured-offices/CROSSCOOP_shinbashi.jpg',
    link: 'https://media.officemap.jp/crosscoop-shimbashi/',
  },
  {
    id: 3,
    name: 'WAW赤坂',
    tagline: '赤坂、眺望と緑を眺めるオープンなシェアオフィス',
    imageUrl: '/images/featured-offices/WAW赤坂.jpg',
    link: 'https://media.officemap.jp/waw-akasaka/',
  },
  {
    id: 4,
    name: 'FARO 中目黒',
    tagline:
      '池尻大橋10分、青葉台エリア目黒川近くに佇む\nアートギャラリー併設の新築シェアオフィス',
    imageUrl: '/images/featured-offices/FARO_nakameguro.webp',
    link: 'https://media.officemap.jp/faro-nakameguro/',
  },
  {
    id: 5,
    name: 'HIBIYA CENTRAL THE WORK PLACE',
    tagline: '2024年リニューアル\n日比谷の街並みと調和する上質でモダンな空間',
    imageUrl: '/images/featured-offices/hibiya_central.webp',
    link: 'https://media.officemap.jp/hibiya-central-the-work-place/',
  },
]

export const FeaturedOffices = () => {
  const displayOffices = FEATURED_OFFICES
  const [currentSlide, setCurrentSlide] = useState(displayOffices.length)
  const [isMobile, setIsMobile] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const sliderRef = useRef<HTMLDivElement>(null)

  // Create array with cloned items for infinite loop
  const extendedOffices = [
    ...displayOffices, // Previous set
    ...displayOffices, // Current set (initial view)
    ...displayOffices, // Next set
  ]

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)

    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  // Auto-slide functionality
  useEffect(() => {
    if (!isPaused && !isTransitioning) {
      const timer = setInterval(() => {
        handleNext()
      }, 5000)

      return () => clearInterval(timer)
    }
  }, [isPaused, isTransitioning])

  const handleTransitionEnd = () => {
    if (!isTransitioning) return

    setIsTransitioning(false)

    // Reset position without animation when reaching clone boundaries
    if (currentSlide >= displayOffices.length * 2) {
      setCurrentSlide(displayOffices.length)
    } else if (currentSlide < displayOffices.length) {
      setCurrentSlide(displayOffices.length * 2 - 1)
    }
  }

  const handleNext = () => {
    if (isTransitioning) return
    setIsTransitioning(true)
    setCurrentSlide((prev) => prev + 1)
  }

  const handlePrev = () => {
    if (isTransitioning) return
    setIsTransitioning(true)
    setCurrentSlide((prev) => prev - 1)
  }

  const goToSlide = (index: number) => {
    setCurrentSlide(displayOffices.length + index)
  }

  if (!displayOffices.length) return null

  const normalizedSlide =
    (currentSlide - displayOffices.length + displayOffices.length * 3) %
    displayOffices.length

  return (
    <section className="w-full bg-white pt-6">
      <div className="mx-auto mb-6 flex justify-center">
        <div className="flex items-center rounded-md px-6 py-2">
          <img
            alt="Office icon"
            className="h-8 w-8"
            src="/icons/ic-office.svg"
          />
          <h4 className="ml-2 text-xl font-bold">おすすめ物件のご紹介</h4>
        </div>
      </div>
      <div
        className="relative mx-auto w-full px-4"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <button
          aria-label="Previous office"
          className="absolute left-9 top-[45%] z-30 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-[11px] bg-black shadow-md md:left-[calc(50%-20%)]"
          onClick={handlePrev}
        >
          <svg
            fill="none"
            height="20"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15 18l-6-6 6-6" />
          </svg>
        </button>
        <button
          aria-label="Next office"
          className="absolute right-9 top-[45%] z-30 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-[11px] bg-black shadow-md md:right-[calc(50%-20%)]"
          onClick={handleNext}
        >
          <svg
            fill="none"
            height="20"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 18l6-6-6-6" />
          </svg>
        </button>

        <div className="overflow-hidden">
          <div
            className={cn(
              'flex transition-transform duration-500 ease-in-out',
              !isTransitioning && 'transition-none',
            )}
            onTransitionEnd={handleTransitionEnd}
            ref={sliderRef}
            style={{
              transform: `translateX(calc(${
                isMobile
                  ? '32.5% - ' + currentSlide * 85 + '% - 25%'
                  : '55% - ' + currentSlide * 40 + '% - 25%'
              }))`,
            }}
          >
            {extendedOffices.map((office, index) => (
              <div
                className="w-[85%] flex-shrink-0 px-[2%] transition-all duration-300 md:w-[40%] md:px-[1%]"
                key={`${office.id}-${index}`}
              >
                <div className="mx-auto w-full max-w-[400px] md:max-w-[700px]">
                  <div className="relative mx-auto h-[240px] overflow-hidden rounded-lg md:h-[320px]">
                    <a
                      href={office.link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        alt={office.name}
                        className="h-full w-full object-cover"
                        src={office.imageUrl}
                      />
                      <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/80 to-transparent px-8 py-4 text-white md:px-4 md:text-left">
                        <p className="text-xs font-medium md:text-sm">
                          {office.name}
                        </p>
                        <p className="line-clamp-3 whitespace-pre-line text-lg font-semibold">
                          {office.tagline}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {displayOffices.length > 1 && (
          <div className="mb-12 mt-6 flex justify-center gap-2">
            {displayOffices.map((office, index) => (
              <button
                aria-label={`Go to slide ${index + 1}`}
                className={cn(
                  'h-[8px] w-[8px] rounded-full transition-all',
                  normalizedSlide === index
                    ? 'w-[18px] bg-[#10909E]'
                    : 'bg-teal-200',
                )}
                key={index}
                onClick={() => goToSlide(index)}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
