import { Container } from '~/components'
import { Office, Prefecture } from '~/models/types'

import {
  AreaSearch,
  FeaturedOffices,
  SearchByMap,
  NewOffices,
} from '../components'

type Props = {
  prefectures: Prefecture[]
  newOffices?: Office[]
}

export const TopPage = ({ prefectures, newOffices = [] }: Props) => {
  return (
    <main className="flex w-full flex-col">
      <SearchByMap />
      <div className="mt-[100px]">
        <FeaturedOffices />
      </div>
      <div className="flex justify-center bg-[#F8F8F8] py-12">
        <Container className="flex flex-col gap-x-8 gap-y-4 md:flex-row">
          <div className="order-2 w-full md:order-1 md:w-[248px]">
            <div className="rounded-lg bg-white p-4 shadow-[0px_4px_40px_0px_rgba(0,0,0,0.08)]">
              <AreaSearch prefectures={prefectures} />
            </div>
          </div>
          <div className="order-1 w-full md:order-2">
            <div className="flex-grow rounded-lg bg-white p-4 shadow-[0px_4px_40px_0px_rgba(0,0,0,0.08)]">
              <NewOffices newOffices={newOffices} />
            </div>
          </div>
        </Container>
      </div>
    </main>
  )
}
