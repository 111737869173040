import { Prefecture } from '~/models/types'

import { AreaList } from './area-list'
import { MainArea } from './main-area'

export const AreaSearch = ({ prefectures }: { prefectures: Prefecture[] }) => {
  return (
    <section
      aria-label="search-by-area"
      className="flex w-full flex-col gap-y-4 md:max-w-[248px]"
    >
      <div className="mr-3 mt-2.5 flex items-center justify-center rounded-md py-2">
        <img alt="Office icon" className="h-8 w-8" src="/icons/ic-search.svg" />
        <h5 className="ml-2 text-xl font-bold">エリアから探す</h5>
      </div>
      <div className="flex flex-col gap-y-6 px-2 md:mx-0">
        <MainArea prefectures={prefectures} />
        <AreaList prefectures={prefectures} />
      </div>
    </section>
  )
}
